import { useState } from "react";
import { useNavigate } from "react-router-dom";

const CreditScorePage = () => {
  const [selectedBvn, setSelectedBvn] = useState("");
  const navigate = useNavigate()

  const placeholderData = [
    {
      name: "Warri Imo",
      img: "/assets/avatar.png",
      phoneNo: "09867823443",
      plan: "CRC and Fc premium",
      bvn: "1234567890",
      date: "08 Oct, 2024",
    },
    {
      name: "Igbo Oghene",
      img: "/assets/avatar.png",
      phoneNo: "0342345643",
      plan: "CRC and Fc premium",
      bvn: "0987654321",
      date: "09 Oct, 2024",
    },
    {
      name: "Grace Rita",
      img: "/assets/avatar.png",
      phoneNo: "09867823443",
      plan: "CRC and Fc premium",
      bvn: "1234567899",
      date: "10 Oct, 2024",
    },
  ];
  
  return (
    <div className="bg-white p-5">
      <h1 className="font-bold text-xl">Credit Insights</h1>

      <section>
        <section className="flex items-end my-3 w-full">
          <div className="w-1/3">
            <label htmlFor="bvn" className="font-semibold">
              BVN
            </label>
            <input
              type="text"
              name="bvn"
              id="bvn"
              value={selectedBvn}
              onChange={(e) => setSelectedBvn(e.target.value)}
              placeholder="Enter BVN"
              className="block text-base mt-2 rounded-l-md p-3 bg-transparent border w-full"
            />
          </div>
          <button
            type="button"
            className="p-3 text-base border text-white rounded-r-md bg-secondary"
            onClick={()=> navigate("credit-details")}
          >
            Generate
          </button>
        </section>

        <section className="my-4">
          <h2 className="font-semibold text-lg">History</h2>

          <section className="my-3">
            <div className="bg-secondary p-4 rounded-t-md text-white">
              <h3 className="font-semibold">Credit insights</h3>
              <p className="text-sm">View your previous credit insights</p>
            </div>
            <table className="w-full border">
              <thead className="text-left border-b">
                <tr>
                  <th className="p-4">Customer</th>
                  <th className="p-4">Plan</th>
                  <th className="p-4">Bvn</th>
                  <th className="p-4">Date</th>
                  <th className="p-4">Actions</th>
                </tr>
              </thead>
              <tbody>
                {placeholderData[0] ? (
                  <tr>
                    <td className="p-4">
                      <p>{placeholderData[0].name.toUpperCase()}</p>
                      <p>{placeholderData[0].phoneNo}</p>
                    </td>
                    <td className="p-4 w-20 overflow-y-hidden text-nowrap truncate">
                      {placeholderData[0].plan}
                    </td>
                    <td className="p-4">{placeholderData[0].bvn}</td>
                    <td className="p-4">{placeholderData[0].date}</td>
                    <td className="p-4">
                      <button
                        type="button"
                        className="border bg-secondary p-2 text-sm text-white"
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan={5} className="text-center p-4">
                      No record
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="flex justify-between items-center p-4 border-x border-b">
              <button type="button">← Prev</button>
              <button type="button">Next →</button>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
};

export default CreditScorePage;
