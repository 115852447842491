import { useState } from "react";
import { IoMdShareAlt } from "react-icons/io";
import { MdFileDownload } from "react-icons/md";
import CreditDetailsBox from "../../components/CreditDetailsBox";
import { BiChevronDown } from "react-icons/bi";

const CreditDetails = () => {
  const [isLoading] = useState(false);
  const data = {
    name: "Warri Imo",
    img: "/assets/avatar.svg",
    phoneNo: "09867823443",
    plan: "CRC and Fc premium",
    bvn: "1234567890",
    date: "08 Oct, 2024",
    loanPerformance: [
      {
        name: "Guaranty Trust Bank Plc",
        loanAmount: 1000,
        balance: 1000,
        count: 1,
        overdue: "2000",
        status: "closed",
        performance: "Performing",
      },
      {
        name: "MyCredit Invest",
        loanAmount: "100000",
        balance: "30000",
        count: 1,
        overdue: "30000",
        status: "open",
        performance: "Not Performing",
      },
    ],
    loanHistory: [
      {
        name: "Guaranty Trust Bank Plc",
        loanAmount: 1000,
        balance: 1000,
        count: 1,
        overdue: "2000",
        status: "closed",
        date: "02/03/2020",
      },
      {
        name: "MyCredit Invest",
        loanAmount: "100000",
        balance: "30000",
        count: 1,
        overdue: "30000",
        status: "open",
        date: "02/03/2020",
      },
    ],
  };

  return (
    <div className="bg-white p-5 rounded-md">
      <h1 className="text-xl mb-3 font-bold">Customer Credit Details</h1>
      <section className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <img
            src={data.img}
            alt={data.name}
            className="w-10 p-1 rounded-full border"
          />
          <h2 className="font-semibold text-lg">{data.name}</h2>
        </div>
        <div className="flex items-center gap-3">
          <button
            type="button"
            className="flex items-center border p-2 bg-gray-50"
          >
            <IoMdShareAlt className="w-6 h-6" />
            Share
          </button>
          <button
            type="button"
            className="flex items-center border p-2 bg-gray-50"
          >
            <MdFileDownload className="w-6 h-6" />
            Download
          </button>
        </div>
      </section>
      <section className="my-2">
        <p className="flex w-1/3 p-1 justify-between">
          <span>Date of birth</span>
          <span className="w-1/2">08/01/1234</span>
        </p>
        <p className="flex w-1/3 p-1 justify-between">
          <span>Gender</span>
          <span className="w-1/2">Male</span>
        </p>
        <p className="flex w-1/3 p-1 justify-between">
          <span>Phone Number</span>
          <span className="w-1/2">{data.phoneNo}</span>
        </p>
        <p className="flex w-1/3 p-1 justify-between">
          <span>BVN</span>
          <span className="w-1/2">{data.bvn}</span>
        </p>
        <p className="flex w-1/3 p-1 justify-between">
          <span>Address</span>
          <span className="w-1/2">4, sdfghjk, sdfghjk</span>
        </p>
      </section>

      <section className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-5 mb-8 mt-[1.5rem]">
        <CreditDetailsBox
          isLoading={isLoading}
          text={"Total no of loans"}
          value={200}
        />
        <CreditDetailsBox
          isLoading={isLoading}
          text={"Total no of institution"}
          value={300}
        />
        <CreditDetailsBox
          isLoading={isLoading}
          text={"Total no of active loans"}
          value={400}
        />
        <CreditDetailsBox
          isLoading={isLoading}
          text={"Total no of closed loans"}
          value={500}
        />
        <CreditDetailsBox
          isLoading={isLoading}
          text={"Total no of performing loans"}
          value={600}
        />
        <CreditDetailsBox
          isLoading={isLoading}
          text={"Total no of delinquent facilities"}
          value={700}
        />
        <CreditDetailsBox
          isLoading={isLoading}
          text={"Highest loan amount"}
          value={800}
        />
        <CreditDetailsBox
          isLoading={isLoading}
          text={"Total monthly installment"}
          value={900}
        />
        <CreditDetailsBox
          isLoading={isLoading}
          text={"Total borrowed"}
          value={900}
        />
        <CreditDetailsBox
          isLoading={isLoading}
          text={"Total outstanding"}
          value={900}
        />
        <CreditDetailsBox
          isLoading={isLoading}
          text={"Total overdue"}
          value={900}
        />
        <CreditDetailsBox
          isLoading={isLoading}
          text={"Max no of days"}
          value={900}
        />
      </section>

      <section className="flex gap-4 justify-between">
        <section className="border rounded-md w-1/3">
          <div className="bg-secondary rounded-t-md p-4">
            <h3 className="text-white font-semibold">Creditors history</h3>
          </div>
          <div className="p-4 border-b">
            <p>Details</p>
          </div>
          <div className="p-4 border-b text-center">
            <p>No data found</p>
          </div>
          <div className="flex justify-between items-center p-4">
            <button type="button">← Prev</button>
            <button type="button">Next →</button>
          </div>
        </section>
        <section className="border rounded-md w-2/3">
          <div className="bg-secondary rounded-t-md p-4">
            <h3 className="text-white font-semibold">
              Credit enquiries the last 12 months
            </h3>
          </div>
          <div className="p-4 border-b flex justify-between">
            <p className="w-1/2">
              <span>Loan Provider</span>
              <span></span>
            </p>
            <p className="w-1/2">
              <span>Date</span>
              <span></span>
            </p>
          </div>
          <div className="p-4 border-b text-center">
            <p>Invalid date</p>
          </div>
          <div className="flex justify-between items-center p-4">
            <button type="button">← Prev</button>
            <button type="button">Next →</button>
          </div>
        </section>
      </section>

      <section className="my-5">
        <h3 className="font-semibold text-lg mb-3">Loan Performance</h3>
        <table className="border w-full">
          <thead>
            <tr className="text-left border-b">
              <th className="py-4 px-2 text-sm font-medium">Loan Provider</th>
              <th className="py-4 px-2 text-sm font-medium">Loan count</th>
              <th className="py-4 px-2 text-sm font-medium">Loan amount</th>
              <th className="py-4 px-2 text-sm font-medium">
                Outstanding balance
              </th>
              <th className="py-4 px-2 text-sm font-medium">Overdue amount </th>
              <th className="py-4 px-2 text-sm font-medium">Status </th>
              <th className="py-4 px-2 text-sm font-medium">
                Performance status{" "}
              </th>
            </tr>
          </thead>
          <tbody>
            {data.loanPerformance.map((item, index) => (
              <tr key={index} className="border-b">
                <td className="py-4 px-2 text-sm">{item.name}</td>
                <td className="py-4 px-2 text-sm">{item.count}</td>
                <td className="py-4 px-2 text-sm">₦{item.loanAmount}</td>
                <td className="py-4 px-2 text-sm">₦{item.balance}</td>
                <td className="py-4 px-2 text-sm">₦{item.overdue}</td>
                <td className="py-3 px-1 text-sm">
                  <span
                    className={
                      item.status === "closed"
                        ? "bg-secondary text-white rounded-md p-1"
                        : "bg-gray-200 rounded-md p-1"
                    }
                  >
                    {item.status}
                  </span>
                </td>
                <td className="py-3 px-1 text-sm flex items-center justify-between">
                  <span
                    className={
                      item.performance === "Performing"
                        ? "bg-secondary text-white rounded-md p-1"
                        : "bg-gray-200 rounded-md p-1"
                    }
                  >
                    {item.performance}
                  </span>
                  <BiChevronDown className="w-5 h-5" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-between items-center p-4 border-x border-b">
          <button type="button">← Prev</button>
          <button type="button">Next →</button>
        </div>
      </section>

      <section className="my-5">
        <h3 className="font-semibold text-lg mb-3">Loan History</h3>
        <table className="border w-full">
          <thead>
            <tr className="text-left border-b">
              <th className="py-4 px-2 text-sm font-medium">Loan Provider</th>
              <th className="py-4 px-2 text-sm font-medium">Loan count</th>
              <th className="py-4 px-2 text-sm font-medium">Loan amount</th>
              <th className="py-4 px-2 text-sm font-medium">
                Outstanding balance
              </th>
              <th className="py-4 px-2 text-sm font-medium">Overdue amount </th>
              <th className="py-4 px-2 text-sm font-medium">Status </th>
              <th className="py-4 px-2 text-sm font-medium">
                Date
              </th>
            </tr>
          </thead>
          <tbody>
            {data.loanHistory.map((item, index) => (
              <tr key={index} className="border-b">
                <td className="py-4 px-2 text-sm">{item.name}</td>
                <td className="py-4 px-2 text-sm">{item.count}</td>
                <td className="py-4 px-2 text-sm">₦{item.loanAmount}</td>
                <td className="py-4 px-2 text-sm">₦{item.balance}</td>
                <td className="py-4 px-2 text-sm">₦{item.overdue}</td>
                <td className="py-3 px-1 text-sm">
                  <span
                    className={
                      item.status === "closed"
                        ? "bg-secondary text-white rounded-md p-1"
                        : "bg-gray-200 rounded-md p-1"
                    }
                  >
                    {item.status}
                  </span>
                </td>
                <td className="py-4 px-2 text-sm flex items-center justify-between">
                  
                    {item.date}
                  <BiChevronDown className="w-5 h-5" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-between items-center p-4 border-x border-b">
          <button type="button">← Prev</button>
          <button type="button">Next →</button>
        </div>
      </section>
    </div>
  );
};

export default CreditDetails;
